<script>
export default {
  props: {
    type: {
      type: String,
      default: "normal",
    },
    text: {
      type: String,
      default: "",
    },
  },
  // 免去的转译的过程
  render(h, params) {
    return h(
      "button",
      {
        // v-bind:class
        class: {
          btn: true,
          "btn-success": this.type === "success",
          "btn-danger": this.type === "danger",
          "btn-warning": this.type === "warning",
          normal: !this.type,
        },
        // dom属性
        // domProps: {
        //   // innerText: this.text || "默认按钮",
        // },
        on: {
          click: this.handleClick,
        },
      },
      this.handleSlot(this.$slots.default)
    );
  },
  methods: {
    handleClick() {
      this.$emit("myEvent", this.handleSlot(this.$slots.default));
    },
    handleSlot(slot) {
      if (slot && slot.length > 0) {
        return slot[0].text;
      }
      return this.text || '默认按钮';
    },
  },
};
</script>
<style scoped>
.btn {
  width: 100px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #000;
  border: none;
  border-radius: 8px;
  line-height: 1;
  border: 1px solid #dcdfe6;
  background: #fff;
  box-sizing: border-box;
}
.btn-success {
  background: green;
  color: #FFFFFF;
}
.btn-danger {
  background: red;
  color: #FFFFFF;
}
.btn-warning {
  background: orange;
  color: #FFFFFF;
}
.normal {
  background: gray;
  color: #FFFFFF;
}
</style>
