import Layout from "@/components/Layouts";
export default {
  path: "/demo",
  name: "Demo",
  meta: {
    title: "演示组件",
    icon: "base",
  },
  redirect: "/demo/infinite-list",
  component: Layout,
  children: [
    {
      path: "/demo/infinite-list",
      name: "InfiniteList",
      meta: { title: "虚拟列表", icon: "nested" },
      component: () => import("@/views/demo/infinite-list.vue"),
    },
  ],
};
