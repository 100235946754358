<!-- 布局 -->
<template>
  <div>
    <section class="layout">
      <layout-aside v-if="sidebar.position === 'left'">
        <template>
          <div
            class="layout-aside-logo"
            :style="{ color: theme.layoutLeftText }"
          >
            <icon name="oa-logo" scale="1"></icon>
            <span class="name" v-if="!isCollapse">XUI组件库</span>
          </div>
        </template>
      </layout-aside>
      <section class="layout-container">
        <layout-header></layout-header>
        <layout-main></layout-main>
        <!-- <layout-footer></layout-footer> -->
      </section>
    </section>
    <config-panel></config-panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { routes } from "@/router/index";
// import { getCache } from "@/utils/auth";
import {
  LayoutAside,
  LayoutHeader,
  LayoutMain,
  LayoutFooter,
} from "./components";
import ConfigPanel from "@/components/Config.vue";
import config from "@/config/config";
export default {
  name: "Layout",
  components: {
    LayoutAside,
    LayoutHeader,
    LayoutMain,
    LayoutFooter,
    ConfigPanel,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("app", ["sidebar", "breadcrumbPosition"]),
    isCollapse() {
      return !this.sidebar.opened;
    },
    theme() {
      return config.get("theme")[this.sidebar.themeType];
    },
  },
  methods: {
    handleClose() {},
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/common-variables.less");
.layout {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  .layout-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .layout-aside-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: @layout-header-height;
    line-height: @layout-header-height;
    color: @layout-left-text;
    font-size: 20px;
    font-weight: 700;
    .logo {
      width: 30px;
      height: 26px;
    }
    .name {
      line-height: 26px;
      margin-left: 3px;
    }
  }
}
</style>
