<!-- 左侧栏 -->
<template>
  <div class="layout-aside" :style="{ backgroundColor: theme.layoutLeftBg }">
    <slot v-if="logoVisible"></slot>
    <el-scrollbar
      class="layout-aside-scrollbar"
      :class="
        mode === 'horizontal' ? 'scrollbar-horizontal' : 'scrollbar-vertical'
      "
    >
      <el-menu
        :class="['layout-aside-menu', isCollapse ? 'collapse' : 'not_collapse']"
        :default-active="defaultActive"
        router
        unique-opened
        :mode="mode"
        :collapse="isCollapse"
        :background-color="theme.layoutLeftBg"
        :text-color="theme.layoutLeftText"
        :active-text-color="theme.layoutLeftActiveText"
      >
        <layout-aside-menu-item
          v-for="item in menuRoutes"
          :item="item"
          :key="item.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import LayoutAsideMenuItem from "./LayoutAsideMenuItem.vue";
import { routes } from "@/router/index";
import { mapGetters } from "vuex";
import config from "@/config/config";

export default {
  name: "LayoutAside",
  components: { LayoutAsideMenuItem },
  props: {
    mode: {
      type: String,
      default: () => "vertical",
    },
  },
  computed: {
    // ...mapGetters("user", ["menuRoutes"]),
    ...mapGetters("app", ["sidebar", "logoVisible"]),
    defaultActive() {
      let matched = this.$route.matched;
      return matched[matched.length - 1].path;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
    theme() {
      return config.get("theme")[this.sidebar.themeType];
    },
    menuRoutes() {
      console.log(routes);
      return routes.filter((item) => !item.isNotLeft);
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
@import url("../../../assets/css/common-variables.less");
.layout-aside {
  .collapse {
    ::v-deep .el-submenu__title .el-submenu__icon-arrow {
      display: none;
    }

    ::v-deep .layout-aside-menu-item .svg-icon + span {
      height: 0;
      width: 0;
      overflow: hidden;
      visibility: hidden;
      display: inline-block;
    }
    .layout-aside-menu {
      &:not(.el-menu--collapse) {
        width: 220px;
      }
    }
  }

  .layout-aside-scrollbar {
    .layout-aside-menu {
      border-right: none;
      ::v-deep .el-submenu__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .scrollbar-vertical {
    ::v-deep .el-scrollbar__wrap {
      min-height: calc(100vh - @layout-header-height);
    }

    .not_collapse {
      width: 220px;
    }
  }
  .scrollbar-horizontal {
    .layout-aside-menu {
      display: flex;
      align-items: center;
    }
    .collapse {
      width: 100%;
      .layout-aside-menu-item {
        width: 150px;
      }
    }
    ::v-deep .layout-aside-menu-item {
      width: 150px;
      .el-submenu__title {
        height: @layout-header-height;
        line-height: @layout-header-height;
      }
    }
  }
}
</style>
