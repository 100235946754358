import Layout from "@/components/Layouts";
export default {
  path: "/mine",
  name: "Mine",
  meta: {
    title: "我的中心",
    icon: "home",
  },
  redirect: "/mine/index",
  component: Layout,
  children: [
    {
      path: "/mine/index",
      name: "OADashboard",
      meta: { title: "仪表盘", icon: "nested" },
      component: () => import("@/views/mine/index.vue"),
    },
    {
      path: "/mine/tax",
      name: "OATax",
      meta: { title: "个税计算", icon: "nested" },
      component: () => import("@/views/mine/tax.vue"),
    },
  ],
};
