import Layout from "@/components/Layouts";
export default {
  path: "/render",
  name: "Render",
  meta: {
    title: "渲染页面",
    icon: "base",
  },
  redirect: "/render/index",
  component: Layout,
  children: [
    {
      path: "/render/index",
      name: "OARender",
      meta: { title: "渲染", icon: "nested" },
      component: () => import("@/views/render/index.vue"),
    },
  ],
};
