<!--  -->
<template>
  <li class="layout-aside-menu-item">
    <el-submenu v-if="item.children" :index="item.path">
      <template slot="title">
        <div class="menu-name">
          <icon
            :name="item.meta && item.meta.icon"
            scale="0.8"
          ></icon>
          <span slot="title">{{ item.meta && item.meta.title }}</span>
        </div>
      </template>
      <layout-aside-menu-item
        v-for="subItem in item.children"
        :item="subItem"
        :key="subItem.path"
      >
      </layout-aside-menu-item>
    </el-submenu>
    <el-menu-item v-else :key="item.path" :index="item.path" @click="handleSelect(item)">
      <template slot="title">
        <div class="menu-name">
          <icon
            :name="item.meta && item.meta.icon"
            scale="0.8"
          ></icon>
          <span slot="title">{{ item.meta && item.meta.title }}</span>
        </div>
      </template>
    </el-menu-item>
  </li>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutAsideMenuItem",
  props: ["item"],
  data() {
    return {};
  },
  computed: {
    // ...mapGetters("layout", ["isCollapse"]),
  },
  methods: {
    handleSelect(item) {
      this.$router.push(item.path);
      // this.$router.replace(item.path)
    }
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.layout-aside-menu-item {
  .svg-icon {
    & + span {
      margin-left: 10px;
    }
  }
  .menu-name {
    text-align: left;
  }
}
</style>
